<template>
  <div class="footer">
      <h3>{{categoryName}}</h3>
      <ul class="footer_list" v-if="isLanding">
        <li v-for="(item, j) in dataByCategory[1]" :key="j"><a :href="getLink(item)"> {{item[1].title}}</a></li>

        <li v-if="!isMoreShow && dataByCategory[2].length > 0" class="show-more-text mt-2"> <a href="#" onclick="return false;" @click="readMore"> {{$t('product-page.read-more')}}</a> </li>
        <div v-if="isMoreShow">
          <li v-for="(item, j) in dataByCategory[2]" :key="j"><a :href="getLink(item)"> {{item[1].title}}</a></li>
        </div>
      </ul>
      <ul class="footer_list" v-else>
        <li v-for="(item, j) in dataByCategory[1]" :key="j"><a :href="getLink(item)"> {{item[1].title}}</a></li>

        <li v-if="!isMoreShow && dataByCategory[2].length > 0" class="show-more-text mt-2"> <a href="#" onclick="return false;" @click="readMore"> {{$t('product-page.read-more')}}</a> </li>
        <div v-if="isMoreShow">
          <li v-for="(item, j) in dataByCategory[2]" :key="j"><a :href="getLink(item)"> {{item[1].title}}</a></li>
        </div>
      </ul>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    dataByCategory: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    categoryName() {
      const category = this.footerCategoryList.find((cat) => (cat.code === this.dataByCategory[0]));
      if (!category) return '';

      switch (this.lang) {
        case 'en':
          return category.nameTranslationEng;
        case 'ar':
          return category.nameTranslationAra;
        case 'ru':
          return category.nameTranslationRus;
        default:
          return category.nameTranslationHeb;
      }
    },
  },
  data() {
    return {
      isMoreShow: false,
    };
  },
  methods: {
    readMore() {
      this.isMoreShow = true;
    },
    getLink(item) {
      if (item[1].link) {
        if (item[1].link.startsWith('http')) {
          return item[1].link;
        } else {
          return `/${item[1].link}`;
        }
      }
      return `/${item[1].slug}`;
    },
  },
};
</script>
<style scoped>
  .show-more-text {
    opacity: 0.5;
  }
</style>
